import { StrictMode, startTransition } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { HydratedRouter } from 'react-router/dom'

if (window.ENV.environment !== 'development') {
	void import('#utils/analytics.client').then(({ init }) => init())
}

if (window.ENV.SENTRY_ENABLED) {
	void import('#utils/monitoring.client').then(({ init }) => init())
}

startTransition(() => {
	hydrateRoot(
		document,
		<StrictMode>
			<HydratedRouter />
		</StrictMode>,
	)
})

declare global {
	interface Window {
		ENV: {
			environment: string
			SENTRY_DSN: string
			SENTRY_ENABLED: boolean
			POSTHOG_API_KEY: string
		}
	}
}
